<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="10" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="Reject Note" vid="reject_note" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="reject_note"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.reject_note')}} <span class="text-danger">*</span>
                          </template>
                          <b-textarea
                              id="reject_note"
                              v-model="reject.reject_note"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Reject Note (Bn)" vid="reject_note_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('pump_install.reject_note_bn')"
                          label-for="reject_note_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <b-textarea
                              id="reject_note_bn"
                              v-model="reject.reject_note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { schemeRejectStore, getLoggedUserSupervisor } from '../../api/routes'

export default {
  props: ['id', 'status'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loading: false,
      reject: {
        reject_note: '',
        reject_note_bn: '',
        scheme_application_id: this.$props.id,
        status: this.$props.status,
        supervisor_name: '',
        supervisor_phone: ''
      }
    }
  },
  created () {
    this.getSupervisor(this.$store.state.Auth.authUser.user_id)
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, schemeRejectStore, this.reject)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    async getSupervisor (loggedUserId) {
      this.loading = true
      await RestApi.getData(authServiceBaseUrl, getLoggedUserSupervisor + '/' + loggedUserId)
      .then(response => {
        if (response.success) {
          this.reject.supervisor_name = response.data.name
          this.reject.supervisor_phone = response.data.phone
        }
        this.loading = false
      })
    }
  }
}
</script>
