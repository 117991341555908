<template>
    <b-container>
        <div style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
        >
            <b-col lg="12" sm="12">
                <b-overlay :show="loading">
                    <b-row>
                        <b-col md="4">
                            <table class="table table-striped" style="border: 1px solid rgb(236, 236, 236);">
                                <thead>
                                <tr class="text-center">
                                    <th scope="col">{{ $t('sitePreference.total') }}</th>
                                    <th scope="col">{{ $t('globalTrans.added') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="text-center">
                                    <th scope="row">
                                        <span><span class="text-danger" style="font-weight: bold">{{ $n(totalFarmers) }}</span></span><br>
                                    </th>
                                    <td>
                                    <span>
                                        <span class="text-success"
                                              style="font-weight: bold">{{ $n(item.added_farmer) || 0 }}
                                        </span>
                                    </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </b-col>
                        <b-col md="8">
                            <iq-card>
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form class="mt-4" @submit.prevent="handleSubmit(update)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col md="6">
                                                <ValidationProvider vid="total">
                                                    <b-form-group
                                                        label-for="note"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <b-form-input
                                                            type="number"
                                                            id="note"
                                                            placeholder="Total farmers"
                                                            v-model="totalFarmers"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col md="6">
                                                <b-button type="submit" variant="success">{{ $t('globalTrans.update') }}</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                            </iq-card>
                        </b-col>
                    </b-row>
                    <b-row v-if="farmerDetails.length > 0">
                        <b-col class="d-flex justify-content-center">
                            <b-button @click="detailOpen = !detailOpen" v-b-toggle.farmer-list-data class="btn btn-light">Show Details <i class="fas" :class="detailOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i></b-button>
                        </b-col>
                    </b-row>
                    <b-collapse v-if="farmerDetails.length > 0" id="farmer-list-data" class="mt-4">
                        <b-row class="mt-4">
                            <b-col v-for="(farmer, index) in farmerDetails" :key="index" md="6">
                                <EditFarmerSingleDetails :farmer="farmer" @reload-component="getData"/>
                                <span style="border-bottom: 3px solid #8b8b8b;"></span>
                            </b-col>
                        </b-row>
                    </b-collapse>
                    <div v-else>
                        <p class="d-flex justify-content-center">No Data</p>
                    </div>
                </b-overlay>
            </b-col>
        </div>
    </b-container>
</template>

<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { schemeFarList, updateFarmer } from '../../../api/routes'
import EditFarmerSingleDetails from './EditFarmerSingleDetails'

export default {
    name: 'FarmerDetailsList',
    props: ['item'],
    components: {
        EditFarmerSingleDetails
    },
    data () {
        return {
            detailOpen: false,
            loading: false,
            totalFarmers: 0
        }
    },
    computed: {
        farmerDetails () {
            return this.$store.state.IrriPumpInstall.farmerDetails
        }
    },
    methods: {
        getData () {
            this.loading = true
            RestApi.getData(irriSchemeServiceBaseUrl, schemeFarList + '/' + this.item.id ?? 0, { application_type: 1 })
                .then((response) => {
                    if (response.success) {
                        this.$store.dispatch('IrriPumpInstall/addFarmerDetails', response.data)
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = true
                })
        },
        update () {
            this.loading = true

            RestApi.putData(irriSchemeServiceBaseUrl, updateFarmer + '/' + this.item.id, { total_farmer: this.totalFarmers })
            .then((response) => {
                if (response.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: response.message
                    })
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                    this.$bvModal.hide('customize-total-farmer')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message
                    })
                }
                this.loading = false
            })
            .catch(() => {
                this.loading = false
            })
        }
    },
    created () {
        this.totalFarmers = this.item.total_farmer
        this.getData()
    }
}
</script>
