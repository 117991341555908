<template>
    <div style="width: 100%">
        <ItemView :propData="itemsToView" :loading="loading">
            <template v-slot:topHeader="">
                <div class="d-flex justify-content-between" style="width: 100%">
                    <span class="text-dark ml-4" style="font-weight: bold;">ID#{{ farmer.id }}</span>
                    <b-button variant=" iq-bg-danger" title="Remove Item" @click="removeFarmer"><i class="fas fa-trash-alt ml-1"></i></b-button>
                </div>
            </template>
        </ItemView>
    </div>
</template>

<script>
import ItemView from '@/components/structure/itemView'
import { Address } from '@/mixins/helper-functions'
import { numberFormatBn } from '@/Utils/fliter'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { removeFarmer } from '../../../api/routes'

export default {
    mixins: [Address],
    props: ['farmer'],
    name: 'EditFarmerSingleDetails',
    data () {
        return {
            reloadComponent: 0,
            loading: false,
            statePrefix: 'ExternalUserIrrigation.commonObj' // Required to get store sates
        }
    },
    components: {
        ItemView
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        farmerName () {
            return this.isBn ? this.farmer.far_name_bn : this.farmer.far_name
        },
        itemsToView () {
            const info = [
                {
                    title1: this.$t('globalTrans.name'),
                    value1: this.farmerName,
                    colSpan: {
                        title1: 1,
                        value1: 4
                    }
                },
                {
                    title1: this.$t('externalUserIrrigation.nid'),
                    value1: numberFormatBn(this.farmer.far_nid, this, { useGrouping: false }),
                    title2: this.$t('irriPumpInformation.mobile'),
                    value2: numberFormatBn(this.farmer.far_mobile_no, this, { useGrouping: false })
                },
                {
                    title1: this.$t('cardPayment.father_name'),
                    value1: this.isBn ? this.farmer.far_father_name_bn : this.farmer.far_father_name,
                    title2: this.$t('cardPayment.mother_name'),
                    value2: this.isBn ? this.farmer.far_mother_name_bn : this.farmer.far_mother_name
                },
                {
                    title1: this.$t('farmerOperator.village'),
                    value1: this.isBn ? this.farmer.far_village_bn : this.farmer.far_village,
                    colSpan: {
                        title1: 1,
                        value1: 4
                    }
                },
                {
                    title1: this.$t('complain.division'),
                    value1: this.getDivision(this.farmer.far_division_id),
                    title2: this.$t('complain.district'),
                    value2: this.getDistrict(this.farmer.far_district_id)
                },
                {
                    title1: this.$t('complain.upazila'),
                    value1: this.getUpazila(this.farmer.far_upazilla_id),
                    title2: this.$t('complain.union'),
                    value2: this.getUnion(this.farmer.far_union_id)
                },
                {
                    title1: this.$t('cardPayment.owned_land'),
                    value1: this.farmer.own_land_amount,
                    title2: this.$t('cardPayment.barga_land'),
                    value2: this.farmer.borga_land_amount
                },
                {
                    title1: this.$t('cardPayment.lease_land'),
                    value1: this.farmer.lease_land_amount,
                    title2: this.$t('cardPayment.total_land'),
                    value2: this.farmer.total_land_amount
                },
                {
                    title1: this.$t('externalUserIrrigation.aus'),
                    value1: this.farmer.aus_crop_land,
                    title2: this.$t('externalUserIrrigation.amon'),
                    value2: this.farmer.amon_crop_land
                },
                {
                    title1: this.$t('externalUserIrrigation.boro'),
                    value1: this.farmer.boro_crop_land,
                    title2: this.$t('globalTrans.other'),
                    value2: this.farmer.other_crop_land
                },
                {
                    title1: this.$t('externalUserIrrigation.remarks'),
                    value1: this.farmer.remarks,
                    colSpan: {
                        title1: 1,
                        value1: 4
                    }
                }
            ]
            return {
                title: '',
                data:
                [
                    {
                        // title: this.$t('externalUserIrrigation.farmer_info'),
                        title: this.farmerName,
                        type: 'table',
                        data: info
                    }
                ]
            }
        }
    },
    methods: {
        removeFarmer () {
            this.$swal({
                title: this.$t('globalTrans.surePrompt'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sendDeleteRequest(this.farmer)
                }
            })
        },
        sendDeleteRequest () {
            this.loading = true
            RestApi.putData(irriSchemeServiceBaseUrl, removeFarmer + '/' + this.farmer.id, { application_id: this.farmer.application_id }).then((response) => {
                if (response.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: response.message
                    })
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                    this.$emit('reload-component')
                    this.loading = false
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message
                    })
                }
            }).catch(() => {
                this.loading = false
                this.$toast.error({
                    title: 'Error',
                    message: 'Something went wrong!'
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
