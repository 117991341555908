<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="3" sm="6">
                          <p class="text-dark">{{ $t('pump_install.irrigation_type') }} : </p>
                        </b-col>
                        <b-col lg="9" sm="6">
                          <p class="text-dark">{{ ($i18n.locale === 'bn') ? scheme.scheme_type_name_bn : scheme.scheme_type_name }} </p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="3" sm="6">
                          <p class="text-dark">{{ $t('pump_install.applicants_name') }} : </p>
                        </b-col>
                        <b-col lg="9" sm="6">
                          <p class="text-dark">{{ ($i18n.locale === 'bn') ? scheme.name_bn : scheme.name }} </p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="3" sm="6">
                          <p class="text-dark">{{ $t('pump_install.scheme_address') }} : </p>
                        </b-col>
                        <b-col lg="9" sm="6">
                          <p class="text-dark">{{ getSchemeLocation (scheme)}} </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <ValidationProvider name="Survey Date" vid="survey_date" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="survey_date"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.survey_date')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              class="date-picker"
                              v-model="survey.survey_date"
                              placeholder="Select Date"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <ValidationProvider name="Suggestion" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="suggestion"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.suggestion')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              id="suggestion"
                              size="sm"
                              v-model="survey.suggestion"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <ValidationProvider name="Suggestion (Bn)" vid="suggestion_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="suggestion_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.suggestion_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              id="suggestion_bn"
                              size="sm"
                              v-model="survey.suggestion_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <h5 class="border-bottom mb-3">{{ $t('pump_install.add_survey_note') }}</h5>
                  <b-row v-for="(note,index) in survey.notes" :key="index">
                    <b-col lg="5" sm="4">
                      <ValidationProvider name="Note" rules="required">
                        <b-form-group
                          label-cols-sm="3"
                          label-for="note"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.note')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              id="note"
                              size="sm"
                              v-model="note.note"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="4">
                      <ValidationProvider name="Note (Bn)" vid="note_bn" rules="required">
                        <b-form-group
                          label-cols-sm="3"
                          label-for="note_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.note_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-textarea
                              id="note_bn"
                              size="sm"
                              v-model="note.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="1" sm="4">
                        <b-button v-if="index !== 0" variant=" iq-bg-danger" size="sm" @click.prevent="removeNote(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        <b-button v-if="index === 0" variant=" iq-bg-success" size="sm" @click="addNew"><i class="ri-add-line m-0"></i></b-button>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { schemeSurveyStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id', 'status', 'details'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.scheme = this.$props.details
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.$t('globalTrans.save'),
      scheme: '',
      survey: {
        survey_date: '',
        applicants_name: '',
        suggestion: '',
        suggestion_bn: '',
        scheme_application_id: this.$props.id,
        status: this.$props.status,
        notes: [
          {
            scheme_application_id: this.$props.id,
            note: '',
            note_bn: ''
          }
        ]
      }
    }
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, schemeSurveyStore, this.survey)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    addNew () {
      const note = {
        scheme_application_id: this.$props.id,
        note: '',
        note_bn: ''
      }
      this.survey.notes.push(note)
    },
    removeNote (key) {
      if (key !== 0) {
        this.survey.notes.splice(key, 1)
      }
    },
    getSchemeLocation (item) {
      const location = []
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDivision.text_bn)
      } else {
        location.push(tmpDivision.text_en)
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDistrict.text_bn)
      } else {
        location.push(tmpDistrict.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUpazila.text_bn)
      } else {
        location.push(tmpUpazila.text_en)
      }
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUnion.text_bn, item.pump_mauza_no_bn, item.pump_jl_no_bn, item.pump_plot_no_bn)
      } else {
        location.push(tmpUnion.text_en, item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no)
      }
      return location.join(', ')
    }
  }
}
</script>
