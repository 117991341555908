<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col lg="12" sm="12">
                  <div>
                    <b-overlay :show="loading">
                      <b-table-simple striped bordered small>
                        <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('pump_install.send_from') }}({{ $t('pump_install.officer_name') }})</b-th>
                            <b-th>{{ $t('pump_install.send_to') }}({{ $t('pump_install.officer_name') }})</b-th>
                            <b-th>{{ $t('pump_install.notel') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(log,index) in logs" :key="log.id">
                            <b-td>{{ $n(sl+index) }}</b-td>
                            <b-td>{{ getOfficerName(log.sender_id) }}</b-td>
                            <b-td>{{ getOfficerName(log.receiver_id) }}</b-td>
                            <b-td>{{ $i18n.locale === 'bn' ? log.note_bn : log.note }}</b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-overlay>
                  </div>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { getUsers, schemeAppLog } from '../../api/routes'

export default {
  props: ['id'],
  data () {
    return {
      sl: 1,
      logs: [],
      userList: [],
      loading: false
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getUserList()
    this.getApplicationLog()
  },
  methods: {
    async getUserList () {
        const result = await RestApi.getData(authServiceBaseUrl, getUsers)
        if (result.success) {
            this.userList = result.data
        }
    },
    async getApplicationLog () {
        this.loading = true
        let result = null
        const applicationId = this.$props.id
        result = await RestApi.getData(irriSchemeServiceBaseUrl, schemeAppLog + '/' + applicationId)
        if (result.success) {
          this.logs = result.data
        } else {
          this.logs = []
        }
        this.loading = false
    },
    getOfficerName (officerId) {
        const officer = this.userList.find(officer => officer.value === officerId)
        if (this.$i18n.locale === 'bn') {
            return officer !== undefined ? officer.text_bn : ''
        } else {
            return officer !== undefined ? officer.text_en : ''
        }
    }
  }
}
</script>
