<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="10" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="SelectOffice" vid="office_type" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_type"
                          slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{ $t('pump_install.select_office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="office_type"
                                  v-model="send.office_type"
                                  @change="changeOfficeType($event)"
                                  :options="officeList"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Select Officer" vid="receiver_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="receiver_id"
                          slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                  {{ $t('pump_install.select_officer')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  id="receiver_id"
                                  v-model="send.receiver_id"
                                  :options="officerList"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="defaultNull">
                                      {{ userLoad ? 'Loading...' : $t('globalTrans.select') }}
                                    </b-form-select-option>
                                  </template>
                              </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Note" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="note"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('pump_install.note')}} <span class="text-danger">*</span>
                          </template>
                          <b-textarea
                              id="note"
                              v-model="send.note"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Note (Bn)" vid="note_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('pump_install.note_bn')"
                          label-for="note_bn"
                          slot-scope="{ valid, errors }"
                          >
                          <b-textarea
                              id="note_bn"
                              v-model="send.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('send')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { getOfficeUser, schemeAppSend } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.$t('pump_install.send'),
        loading: false,
        send: {
            office_type: 0,
            id: this.$props.id,
            sender_id: this.$store.state.Auth.authUser.user_id,
            receiver_id: 0,
            note: '',
            note_bn: ''
        },
        defaultNull: 0,
        userLoad: true,
        officeList: [
            {
                value: 1,
                text: this.$i18n.locale === 'bn' ? 'নিজস্ব অফিস' : 'Own Office'
            },
            {
                value: 2,
                text: this.$i18n.locale === 'bn' ? 'প্যারেন্ট অফিস' : 'Parent Office'
            },
            {
                value: 3,
                text: this.$i18n.locale === 'bn' ? 'চাইল্ড অফিস' : 'Child Office'
            }
        ],
        officerList: []
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.postData(irriSchemeServiceBaseUrl, schemeAppSend, this.send)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('send')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    changeOfficeType (officeType) {
      this.userLoad = true
      const myOfficeId = this.$store.state.Auth.authUser.office_detail.id
      let officeId = ''
      if (officeType === 1) {
        officeId = myOfficeId
      } else if (officeType === 2) {
        officeId = this.$store.state.Auth.authUser.office_detail.parent_office_id
      } else {
        const childOffice = this.$store.state.commonObj.officeList.find(office => office.parent_office_id === myOfficeId)
        officeId = childOffice.value
      }
      RestApi.getData(authServiceBaseUrl, getOfficeUser + '/' + officeId).then(response => {
        if (response.success) {
          this.officerList = this.getOfficerDesignation(response.data)
        } else {
          this.officerList = []
        }
        this.userLoad = false
      })
    },
    getOfficerDesignation (data) {
      return data.map(item => {
        return Object.assign({}, item, {
          value: item.id,
          text: this.$i18n.locale === 'bn' ? item.name_bn + this.getDesignation(item.designation_id) : item.name + this.getDesignation(item.designation_id)
        })
      })
    },
    getDesignation (designationId) {
      const designation = this.$store.state.commonObj.designationList.find(desg => desg.value === designationId)
      return designation !== undefined ? (this.$i18n.locale === 'bn' ? '(' + designation.text_bn + ')' : '(' + designation.text_en + ')') : ''
    }
  }
}
</script>
