<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.application') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.application_id')"
              label-for="application_id"
            >
              <b-form-input
                id="application_id"
                v-model="search.application_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="search.name"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.scheme_typel')"
              label-for="scheme_type_id"
            >
              <b-form-select
                plain
                id="scheme_type_id"
                v-model="search.scheme_type_id"
                :options="SchemeTypeList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.union')"
              label-for="far_union_id"
            >
              <b-form-select
                plain
                id="far_union_id"
                v-model="search.far_union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mobile')"
              label-for="far_mobile_no"
            >
              <b-form-input
                plain
                id="far_mobile_no"
                v-model="search.far_mobile_no"
                >
                </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.father_name')"
              label-for="father_name"
            >
              <b-form-input
                plain
                id="father_name"
                v-model="search.father_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.mother_name')"
              label-for="mother_name"
            >
              <b-form-input
                plain
                id="mother_name"
                v-model="search.mother_name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.nid')"
              label-for="nid"
            >
              <b-form-input
                plain
                id="nid"
                v-model="search.nid"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="text-right">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.application_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, { useGrouping:false }) }}
                    </template>
                    <template v-slot:cell(sch_man_name)="data">
                      {{ data.item.sch_man_name }}
                    </template>
                    <template v-slot:cell(application_type)="data">
                      {{ applicationType(data.item.application_type_id) }}
                    </template>
                    <template v-slot:cell(farmer_info)="data">
                        <table>
                            <tr>
                                <td>
                                    <span class="d-flex">{{ $t('sitePreference.total') }}: <span class="text-danger" style="font-weight: bold; margin-left: 2px;"> {{ $n(data.item.total_farmer) }}</span></span>
                                </td>
                                <td>
                                    <span class="d-flex" style="min-width: 60px;">
                                        <a href="javascript:"
                                           v-b-tooltip.hover
                                           @click="openFarDetails(data.item)"
                                           title="View Details">{{ $t('pump_install.farmer_added') }}:
                                            <span class="text-success"
                                                  style="font-weight: bold">{{ $n(data.item.added_farmer) || 0 }}
                                            </span>
                                        </a>
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="data.item.status === 14">
                                <td>
                                    <b-button
                                        variant=" iq-bg-success mr-1 pl-3"
                                        size="sm"
                                        title="Customize Details"
                                        @click="customizeTotalFarmer(data.item)">
                                        <i class="far fa-edit"></i>
                                    </b-button>
                                </td>
                                <td>
                                    <b-button
                                        v-if="data.item.total_farmer > data.item.added_farmer"
                                        v-b-modal.farmer-form
                                        variant=" iq-bg-success"
                                        size="sm"
                                        title="Add Details"
                                        @click="view(data.item)">
                                        Add +
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </template>
                    <template v-slot:cell(attached_document_link)="data">
                      <a v-if="data.item.general_minutes !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/general-minutes/original/' + data.item.general_minutes" title="General Minute" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_lands !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-lands/original/' + data.item.scheme_lands" title="Scheme Land" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                      <a v-if="data.item.scheme_map !== null" :href="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-map/original/' + data.item.scheme_map" title="Scheme Map"><i class="ri-download-cloud-fill"></i></a>
                    </template>
                    <template v-slot:cell(project)="data">
                      <div v-if="data.item.scheme_project_id !== null">
                        {{ getProjectName(data.item.scheme_project_id) }}
                      </div>
                      <div v-else>
                        <a href="javascript:" class="btn_table_action table_action_status" v-b-modal.modal-1 title="Assign Project" @click="setRowId(data.item, 1)"><i class="fas fa-plus"></i></a>
                      </div>
                    </template>
                    <template v-slot:cell(technical_survey_report)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" v-if="data.item.survey_id !== null" v-b-modal.modal-6 title="Show Report" @click="setRowId(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-light">{{ getStatus(data.item.status) }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-if="$store.state.Auth.authUser.ssoLogin && data.item.status !== 16" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="sendToNothi(data.item)">
                        <i class="far fa-check-square m-0"></i> {{$t('elearning_venue.forward_to_nothi')}}
                      </b-button>
                      <b-button v-if="$store.state.Auth.authUser.ssoLogin && data.item.status !== 16" style="font-size: 12px !important;" class="mr-1" variant="btn btn-xs btn-success" size="sm" @click="sendToNothi(data.item)" ><i class="fa fa-forward"></i> {{$t('globalTrans.to_nothi')}}</b-button>
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-7 variant=" iq-bg-success mr-1" size="sm" title="View" @click="setRowId(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_msg" v-if="data.item.status === 1" v-b-modal.modal-3 title="Return" @click="setRowId(data.item, 4)"><i class="far fa-pause-circle mr-1"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.survey_id === null" v-b-modal.modal-4 title="Survey Report" @click="setRowId(data.item, 2)"><i class="ri-file-chart-line mr-1"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status <= 2" v-b-modal.send title="Send" @click="send(data.item)"><i class="ri-arrow-right-line mr-1"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" v-if="data.item.status <= 2" v-b-modal.modal-5 title="Reject" @click="setRowId(data.item, 5)"><i class="ri-close-line"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.log title="Application Log" @click="setRowId(data.item, 6)"><i class="ri-list-check"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="paginationDataLoad"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="lg" :title="$t('pump_install.assign_project')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <AssignProject :id="id" :org_id="org_id" :status="status" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-3" size="lg" :title="$t('pump_install.review')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Review :id="id" :status="status" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-4" size="lg" :title="$t('pump_install.survey')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Survey :id="id" :status="status" :details="details" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('pump_install.reject')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Reject :id="id" :status="status" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="$t('pump_install.survey_report')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <SurveyReport :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="modal-7" size="xl" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <ApplicationDetails :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="customize-total-farmer" size="xl" :title="$t('externalUserIrrigation.farmer_info')"
           :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <EditFarmerDetailsList :item="currentItem" />
    </b-modal>
    <b-modal id="farmer-form" size="xl" :title="$t('externalUserIrrigation.farmer_info')"
           :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <FarmerForm :appId="currentAppId" @added="loadData()" />
    </b-modal>
    <b-modal id="farmer-details-list" size="xl" :title="$t('externalUserIrrigation.farmer_info')"
           :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :appId="currentAppId" />
    </b-modal>
    <b-modal id="send" size="lg" :title="$t('pump_install.send')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Send :id="id" :key="id"/>
      </p>
    </b-modal>
    <b-modal id="log" size="lg" :title="$t('pump_install.application_log')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Log :id="id" :key="id"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import AssignProject from './AssignProject'
import Review from './Review'
import Survey from './Survey'
import SurveyReport from './SurveyReport'
import ApplicationDetails from '../SchemeApplicationDetails'
import Reject from './Reject'
import Send from './Send'
import Log from './Log'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { applicationList, applicationStatusUpdate, nothiSend } from '../../api/routes'
import Details from './farmer/Details'
import EditFarmerDetailsList from './farmer/EditFarmerDetailsList'
import { mapGetters } from 'vuex'
import Store from '@/store'
import FarmerForm from '@/modules/external-user-service/irrigation/pages/scheme-application/scheme-farmer-land-details/FarmerForm'

export default {
  name: 'List',
  components: {
    FarmerForm,
    AssignProject,
    Review,
    Survey,
    SurveyReport,
    ApplicationDetails,
    Reject,
    Send,
    Log,
    EditFarmerDetailsList,
    Details
  },
  data () {
    return {
      application: '',
      applications: [],
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      loggedInUserRoleId: this.$store.state.Auth.authUser.role_id,
      baseUrl: irriSchemeServiceBaseUrl,
      search: {
        application_id: '',
        name: '',
        scheme_type_id: 0,
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        far_union_id: 0,
        far_mobile_no: '',
        father_name: '',
        mother_name: '',
        nid: ''
      },
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      status: '',
      details: '',
      org_id: 0,
      currentAppId: 0,
      currentItem: {}
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    formTitle () {
       return (this.id === 0) ? this.$t('irrigation_config.scheme_type_entry') : this.$t('irrigation_config.scheme') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('pump_install.application_id'), class: 'text-center' },
        { label: this.$t('pump_install.scheme_manager'), class: 'text-center' },
        { label: this.$t('pump_install.application_type'), class: 'text-center' },
        { label: this.$t('pump_install.farmer_info'), class: 'text-left expand-column' },
        { label: this.$t('pump_install.attachment'), class: 'text-center' },
        { label: this.$t('pump_install.project'), class: 'text-center' },
        { label: this.$t('pump_install.technical_survey_report'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name_bn' },
          { key: 'application_type' },
          { key: 'farmer_info' },
          { key: 'attached_document_link' },
          { key: 'project' },
          { key: 'technical_survey_report' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'sch_man_name' },
          { key: 'application_type' },
          { key: 'farmer_info' },
          { key: 'attached_document_link' },
          { key: 'project' },
          { key: 'technical_survey_report' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    SchemeTypeList: function () {
      return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(item => item.status === 0)
    },
    locale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    getProjectName (id) {
      const project = this.$store.state.IrriConfig.commonObj.projectList.find(item => item.value === Number(id))
      if (this.$i18n.locale === 'bn') {
        return project !== undefined ? project.text_bn : ''
      } else {
        return project !== undefined ? project.text_en : ''
      }
    },
    view (item) {
      this.currentAppId = item.id
    },
    openFarDetails (item) {
      this.currentAppId = item.id
      this.$bvModal.show('farmer-details-list')
    },
    customizeTotalFarmer (item) {
      this.currentItem = item
      this.$bvModal.show('customize-total-farmer')
    },
    resetId () {
      this.id = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    setRowId (item, status = 1) {
      this.id = item.id
      this.org_id = item.org_id
      this.status = status
      this.details = item
    },
    send (item) {
      this.id = item.id
    },
    async statusUpdate (item, status) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.deleteData(irriSchemeServiceBaseUrl, `${applicationStatusUpdate}/${item.id}/${status}`)
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data Updated Successfully',
          color: '#D6E09B'
        })
        this.loading = false
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    async loadData () {
      const status = this.$route.query.status
      let searchData = ''
      if (status !== undefined) {
        searchData = Object.assign({}, this.search, { status: status })
      } else {
        searchData = this.search
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, searchData, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, applicationList, params).then(response => {
        if (response.success) {
          this.applications = this.getRelationalData(response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const orgList = this.$store.state.orgList

      const datas = data.map(item => {
        const farDivision = divisionList.find(div => div.value === item.far_division_id)
        const farDistrict = districtList.find(dist => dist.value === item.far_district_id)
        const farUpazilla = upazilaList.find(upz => upz.value === item.far_upazilla_id)
        const farUnion = unionList.find(un => un.value === item.far_union_id)
        const org = orgList.find(org => org.value === item.org_id)

        const pumpDivision = divisionList.find(div => div.value === item.pump_division_id)
        const pumpDistrict = districtList.find(dist => dist.value === item.pump_district_id)
        const pumpUpazilla = upazilaList.find(upz => upz.value === item.pump_upazilla_id)
        const pumpUnion = unionList.find(un => un.value === item.pump_union_id)

        const schManDivision = divisionList.find(div => div.value === item.sch_man_division_id)
        const schManDistrict = districtList.find(dist => dist.value === item.sch_man_district_id)
        const schManUpazilla = upazilaList.find(upz => upz.value === item.sch_man_upazilla_id)
        const schManUnion = unionList.find(un => un.value === item.sch_man_union_id)

        const farAddress = {
          far_division_name: typeof farDivision !== 'undefined' ? farDivision.text_en : '',
          far_division_name_bn: typeof farDivision !== 'undefined' ? farDivision.text_bn : '',
          far_district_name: typeof farDistrict !== 'undefined' ? farDistrict.text_en : '',
          far_district_name_bn: typeof farDistrict !== 'undefined' ? farDistrict.text_bn : '',
          far_upazilla_name: typeof farUpazilla !== 'undefined' ? farUpazilla.text_en : '',
          far_upazilla_name_bn: typeof farUpazilla !== 'undefined' ? farUpazilla.text_bn : '',
          far_union_name: typeof farUnion !== 'undefined' ? farUnion.text_en : '',
          far_union_name_bn: typeof farUnion !== 'undefined' ? farUnion.text_bn : '',
          org_name: typeof org !== 'undefined' ? org.text_en : '',
          org_name_bn: typeof org !== 'undefined' ? org.text_bn : ''
        }

        const pumpAddress = {
          pump_division_name: typeof pumpDivision !== 'undefined' ? pumpDivision.text_en : '',
          pump_division_name_bn: typeof pumpDivision !== 'undefined' ? pumpDivision.text_bn : '',
          pump_district_name: typeof pumpDistrict !== 'undefined' ? pumpDistrict.text_en : '',
          pump_district_name_bn: typeof pumpDistrict !== 'undefined' ? pumpDistrict.text_bn : '',
          pump_upazilla_name: typeof pumpUpazilla !== 'undefined' ? pumpUpazilla.text_en : '',
          pump_upazilla_name_bn: typeof pumpUpazilla !== 'undefined' ? pumpUpazilla.text_bn : '',
          pump_union_name: typeof pumpUnion !== 'undefined' ? pumpUnion.text_en : '',
          pump_union_name_bn: typeof pumpUnion !== 'undefined' ? pumpUnion.text_bn : ''
        }

        const schManAddress = {
          sch_man_division_name: typeof schManDivision !== 'undefined' ? schManDivision.text_en : '',
          sch_man_division_name_bn: typeof schManDivision !== 'undefined' ? schManDivision.text_bn : '',
          sch_man_district_name: typeof schManDistrict !== 'undefined' ? schManDistrict.text_en : '',
          sch_man_district_name_bn: typeof schManDistrict !== 'undefined' ? schManDistrict.text_bn : '',
          sch_man_upazilla_name: typeof schManUpazilla !== 'undefined' ? schManUpazilla.text_en : '',
          sch_man_upazilla_name_bn: typeof schManUpazilla !== 'undefined' ? schManUpazilla.text_bn : '',
          sch_man_union_name: typeof schManUnion !== 'undefined' ? schManUnion.text_en : '',
          sch_man_union_name_bn: typeof schManUnion !== 'undefined' ? schManUnion.text_bn : ''
        }

        return Object.assign(item, farAddress, pumpAddress, schManAddress)
      })
      return datas
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.selected')
      } else if (status === 4) {
        return this.$t('pump_install.review')
      } else if (status === 5) {
        return this.$t('pump_install.reject')
      } else if (status === 6) {
        return this.$t('pump_install.current_status')
      } else if (status === 7) {
        return this.$t('irrigation_task.verified')
      } else if (status === 8) {
        return this.$t('pump_install.agreement')
      } else if (status === 9) {
        return this.$t('pump_install.requisition')
      } else if (status === 10) {
        return this.$t('pump_install.supply')
      } else if (status === 11) {
        return this.$t('pump_install.installation')
      } else if (status === 12) {
        return this.$t('pump_install.participation_fee')
      } else if (status === 13) {
        return this.$t('pump_install.close')
      } else if (status === 14) {
        return this.$t('pump_install.installed')
      } else if (status === 15) {
        return this.$t('pump_install.send')
      } else if (status === 16) {
        return this.$t('pump_install.forwarded_to_nothi')
      }
    },
    applicationType (type) {
      if (type === 1) {
        return this.$t('pump_install.new')
      } else if (type === 2) {
        return this.$t('pump_install.resunk')
      }
    },
    sendToNothi (item) {
      this.toggleStatusCustom2(irriSchemeServiceBaseUrl, nothiSend, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true })
      RestApi.postData(baseUrl, uri, item).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
