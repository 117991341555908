<template>
    <b-container>
        <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
        >
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <form-input :data="formInputData" :load="load" @change="setData" @submit="saveData" />
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Address, Repository } from '@/mixins/helper-functions'
import { schemeFarStore } from '../../../api/routes'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'

export default {
    name: 'FarmerForm',
    mixins: [Address],
    props: ['appId'],
    components: {
        FormInput
    },
    data () {
        return {
            load: false,
            statePrefix: 'ExternalUserIrrigation.commonObj', // Required to get store sates
            farmerDetails: {
                application_id: this.appId,
                application_type: 1,
                far_division_id: '',
                far_district_id: '',
                far_upazilla_id: '',
                far_union_id: '',
                own_land_amount: '',
                lease_land_amount: '',
                borga_land_amount: '',
                total_land_amount: ''
            },
            total_land_amount: ''
        }
    },
    watch: {
        'farmerDetails.far_division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'farmerDetails.far_district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'farmerDetails.far_upazilla_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.unionList = this.getUnionList(newVal)
            }
        },
        'farmerDetails.borga_land_amount': function (newVal, oldVal) {
            this.calculateTotal()
        },
        'farmerDetails.own_land_amount': function (newVal, oldVal) {
            this.calculateTotal()
        },
        'farmerDetails.lease_land_amount': function (newVal, oldVal) {
            this.calculateTotal()
        }
    },
    computed: {
        totalLandAmount () {
          return this.total_land_amount
        },
        formInputData () {
            const parent = Object.assign(this.farmerDetails, { total_land_amount: this.totalLandAmount })
            return {
                chunk: true,
                buttonLocale: 'globalTrans.save_application_form',
                buttonClass: 'btn btn-mary btn-block',
                dataParent: parent,
                data: [
                    {
                        head: {
                            title: 'globalTrans.personal_information'
                        },
                        items: [
                            {
                                name: 'far_name',
                                type: 'Input',
                                label: 'globalTrans.name'
                            },
                            {
                                name: 'far_name_bn',
                                type: 'Input',
                                label: 'globalUserData.name_bn',
                                labelCol: 4
                            },
                            {
                                name: 'far_mobile_no',
                                type: 'Input',
                                inputType: 'number',
                                label: 'globalUserData.mobile_no'
                            },
                            {
                                name: 'far_nid',
                                type: 'Input',
                                inputType: 'number',
                                label: 'externalUserIrrigation.nid',
                                labelCol: 4
                            },
                            {
                                name: 'far_father_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_name'
                            },
                            {
                                name: 'far_father_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.father_name_bn',
                                labelCol: 4
                            },
                            {
                                name: 'far_mother_name',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_name'
                            },
                            {
                                name: 'far_mother_name_bn',
                                type: 'Input',
                                label: 'externalUserIrrigation.mother_name_bn',
                                labelCol: 4
                            },
                            {
                                name: 'far_village',
                                type: 'Input',
                                label: 'farmerOperator.village'
                            },
                            {
                                name: 'far_village_bn',
                                type: 'Input',
                                label: 'farmerOperator.village_bn',
                                labelCol: 4
                            },
                            {
                                name: 'far_division_id',
                                type: 'Select',
                                label: 'org_pro_division.division',
                                options: this.divisionList,
                                rules: 'required|min_value:1'
                            },
                            {
                                name: 'far_district_id',
                                type: 'Select',
                                label: 'org_pro_district.district',
                                options: this.districtList,
                                labelCol: 4
                            },
                            {
                                name: 'far_upazilla_id',
                                type: 'Select',
                                label: 'org_pro_upazilla.upazilla',
                                options: this.upazilaList
                            },
                            {
                                name: 'far_union_id',
                                type: 'Select',
                                label: 'org_pro_union.union',
                                options: this.unionList,
                                labelCol: 4
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'globalTrans.land_information'
                        },
                        items: [
                            {
                                name: 'own_land_amount',
                                type: 'Input',
                                label: 'farmerOperator.owned_land'
                            },
                            {
                                name: 'lease_land_amount',
                                type: 'Input',
                                label: 'farmerOperator.lease_land'
                            },
                            {
                                name: 'borga_land_amount',
                                type: 'Input',
                                label: 'farmerOperator.barga_land'
                            },
                            {
                                name: 'total_land_amount',
                                type: 'Input',
                                label: 'farmerOperator.total_land',
                                readOnly: true
                            }
                        ]
                    },
                    {
                        head: {
                            title: 'externalUserIrrigation.crop_details'
                        },
                        items: [
                            {
                                name: 'aus_crop_land',
                                type: 'Input',
                                label: 'externalUserIrrigation.aus',
                                rules: 'nullable'
                            },
                            {
                                name: 'amon_crop_land',
                                type: 'Input',
                                label: 'externalUserIrrigation.amon',
                                rules: 'nullable'
                            },
                            {
                                name: 'boro_crop_land',
                                type: 'Input',
                                label: 'externalUserIrrigation.boro',
                                rules: 'nullable'
                            },
                            {
                                name: 'other_crop_land',
                                type: 'Input',
                                label: 'globalTrans.other',
                                rules: 'nullable'
                            },
                            {
                                name: 'remarks',
                                type: 'Textarea',
                                label: 'externalUserIrrigation.remarks',
                                rules: 'nullable'
                            }
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        calculateTotal () {
            this.farmerDetails.total_land_amount = ''
            const total = (parseFloat(this.farmerDetails.borga_land_amount) || 0) +
                (parseFloat(this.farmerDetails.own_land_amount) || 0) +
                (parseFloat(this.farmerDetails.lease_land_amount) || 0)
            this.total_land_amount = total.toFixed(2)
        },
        setData (data) {
            this.farmerDetails = Object.assign(this.farmerDetails, data)
        },
        async saveData () {
            this.load = true
            const result = await Repository.create({
                data: this.farmerDetails,
                baseURL: irriSchemeServiceBaseUrl,
                storeAPI: schemeFarStore,
                modalId: 'farmer-form'
            })

            if (result.success === true) {
                this.$emit('added')
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
            }
            this.load = false
        }
    }
}
</script>
